<template>
  <div class="wallpaper-grid">
      <div class="wallpaper-entry" v-for="img in thumbs" :key="img"><img :src="`wallpapers/thumbnails/${img}`" />
        <div class="wallpaper-links">
          <template v-for="[k,v] in mappings()" :key="k"><a :href="`wallpapers/${k}/${img}`">{{v}}</a></template>
        </div>
      </div>
  </div>
</template>

<script>
export default {  
  methods:
    {
      mappings: function()
      {
        return [  ['16_9/1080p', '1920x1080' ], [ '16_9/1440p', '2560x1440' ], ['16_9/2160p', '3840x2160' ], 
                  ['21_9/1080p', '2560x1080' ], [ '21_9/1440p', '3440x1440' ], ['21_9/2160p', '5120x2160'] ];
      }
    },
  data() {
    let thumbs = require.context('/public/wallpapers/thumbnails', true, /^.*\.png$/);
      return { thumbs :  thumbs.keys().map( x => x ) };
    }
}
</script>

<style lang="scss" scoped>
  .wallpaper-links
  {
    display: flex;
    flex-wrap: wrap;
    -webkit-column-count:3;
    -moz-column-count:3;
    column-count: 3;
    row-gap: 0px;
    justify-items: center;
    align-items: center;
    a
    {
      text-align: center;
      flex-grow: 1;
      width:33%;
      display: block;
    }
  }
  .wallpaper-grid
  {
    width: 95%; //save 5% for margins when squished
    max-width: 850px;
    margin: auto auto;
    margin-top: 50px;
    margin-bottom: 75px;
    column-count: 3;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  img
  {
    max-width: 100%;
    width: 280px;
    margin-top: auto;
    height:auto;
  }
  .wallpaper-entry
  {
    text-align:center;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 768px)
  {
    .wallpaper-entry
    {
      margin: 0 10%;
    }
    .wallpaper-grid
    {
      grid-template-columns: 1fr;
    }
    img
    {
      width: 90%;
    }
  }
</style>
